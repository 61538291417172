<template>
	<div class="container d-flex flex-row align-items-end">
		<div class="row">
			<div class="col-12">
				<h1>Deployments made simple</h1>
				<h6>Easy way to deploy even the most grotesque website</h6>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Landing"
}
</script>

<style scoped>

</style>